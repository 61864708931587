<!--
 * @Author: 朱廷果 1028509503@qq.com
 * @Date: 2022-10-09 17:05:05
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2022-12-30 15:27:10
 * @FilePath: \dataview-next\src\manage-views\views\sysSetting\config\components\userDialog.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%A

-->
<template>
  <div class="user-dialog">
    <el-dialog
      :visible.sync="dialogs"
      width="80%"
      title='选择导入数据'
      :before-close="onClose"
    >
      <el-table
        :data="importData"
        :header-cell-style="{ background: '#fafafa' }"
        class="config-table"
        @selection-change="onSelectChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column label="参数编码" prop="code"></el-table-column>
        <el-table-column label="参数名称" prop="name"></el-table-column>
        <el-table-column label="参数值" prop="value"> </el-table-column>
        <el-table-column label="是否内置参数" align="center" width="150px">
          <template #default="{ row }">
            <div>{{ row.is_inside ? '是' : '否' }}</div>
          </template>
        </el-table-column>
        <el-table-column label="备注" prop="remarks"> </el-table-column>
        <el-table-column
          label="创建时间"
          prop="created_at"
          align="left"
          width="150px"
        ></el-table-column>
      </el-table>
      <div slot="footer">
        <el-button size="mini" @click="onClose">取消</el-button>
        <el-button size="mini" type="primary" @click="onSave">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { importSetting } from '@/api/saasManage'
export default {
  components: {},
  props: {
    importDialog: {
      type: Boolean,
      default: false,
    },
    importData:{
      type:Array,
      default:()=>[]
    },
  },
  data() {
    return {
      dialogs:false,
      parentSelectId:[]
    };
  },
  watch: {
    importDialog(newVal) {
      this.dialogs = newVal
    },
  },
  methods: {
    onSelectChange(val) {
      this.parentSelectId = []
      val.forEach((data) => {
        this.parentSelectId.push(data.id)
      })
    },
    onSave(){
      importSetting({id:this.parentSelectId}).then(res=>{
        if (res.data && res.data.code !== 200) {
          return false
        }
        this.$emit('dialogSave')
      })
    },
    onClose(){
      this.dialogs = false
      this.$emit('dialogClose')
    }
  },
};
</script>
<style lang="less" scoped>
@import '../../../../css/manageAdd.less';
</style>
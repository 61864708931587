<template>
  <div class="config-edit-main">
    <el-drawer
      title="修改参数"
      :visible.sync="drawers"
      :before-close="onClose"
      >
      <div class="form-class">
        <el-form 
          ref='form' 
          :model ='form'  
          label-position="top" 
          :rules="rules">
          <el-form-item label="参数名称" prop='name'>
            <el-input :disabled='!isSuper' v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="参数编码" prop='code'>
            <el-input :disabled='!isSuper' v-model="form.code"></el-input>
          </el-form-item>
          <el-form-item label="控件类型" prop='type' v-if='isSuper'>
              <el-select class="form-option"  v-model="type" placeholder="请选择控件类型">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
          </el-form-item>
          <el-form-item label="参数值" prop='value' >
            <div v-if="type === 'select' || type === 'radio'">
              <el-select
                v-model="form.value"
                placeholder="请选择参数值"
              >
                <el-option
                  v-for="(item, index) in typeList"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <el-input
              v-else
              class="form-input"
              :disabled="type === 'switch' || type === 'upload'"
              v-model="form.value"
            ></el-input>
            <div v-if="type === 'switch'">
              <el-switch
                v-model="switchValue"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
            </div>
            <Upload
              @onSuccess="onSuccess"
              :title="'上传图片'"
              v-if="type === 'upload'"
            >
            </Upload>
          </el-form-item>
          <el-form-item label="是否内置参数" prop='sort' v-if='isSuper'>
            <el-radio-group v-model="form.is_inside">
              <el-radio-button label="1">是</el-radio-button>
              <el-radio-button label="0">否</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="备注" v-if='isSuper'>
            <el-input type="textarea" :disabled='!isSuper' v-model="form.remarks"></el-input>
          </el-form-item>
          <el-form-item class="bottom-btn">
            <el-button size="mini" @click="onClose">取消</el-button>
            <el-button size="mini" type="primary" @click='onSave'>保存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import Upload from '../../../personalCenter/components/companyConfig/components/upload'
import { editConfig } from '@/api/saasManage'
import { RadioButton, RadioGroup } from 'element-ui';
import { deepClone } from '@topology/core';
export default {
  name:'EditDrawer',
  components: { 
    Upload,  
    'el-radio-group': RadioGroup,
    'el-radio-button': RadioButton,
  },
  props: {
    drawer:{
      type:Boolean,
      default:false,
    },
    editData:{
      type:Object,
      default:() => {}
    }
  },
  data() {
    return {
      switchValue:true,
      typeList: [{ value: '', label: '' }],
      type:'', // 控件类型
      options: [{
        value: 'input',
        label: '输入框'
      }, {
        value: 'radio',
        label: '单选框'
      }, {
        value: 'select',
        label: '下拉框'
      }, {
        value: 'switch',
        label: '开关'
      }, {
        value: 'upload',
        label: '文件上传'
      }],
      drawers:false,
      form:{},
      rules:{
        code:[
           { required: true, message: '参数编码不能为空', trigger: 'blur' },
        ],
        name:[
           { required: true, message: '名称不能为空', trigger: 'blur' },
        ],
        value:[
           { required: true, message: '数据值不能为空', trigger: 'blur' },
        ],
      },
      isSuper:false,
    };
  },
  watch: {
    drawer(newVal){
      this.drawers = newVal;
    },
    editData(newVal){
      if(newVal && newVal.id){
        this.form = deepClone(newVal);
        this.type = newVal.type;
        this.typeList = newVal.options;
        if(newVal.type==='switch'){
          // 属性是开关把属性改为布尔值
          this.switchValue = eval(newVal.value.toLowerCase());
        }else{
          this.switchValue = newVal.value
        }
        
      }
    },
    switchValue(newVal) {
      this.form.value = newVal + ''
    },
  },
  mounted() {
    this.isSuper = JSON.parse(localStorage.getItem("userInfo")).saas_admin
  },
  methods: {
    onSuccess(data){
      this.form.value = data
    },
    // typeChange(data) {
    //   if (data === 'switch') {
    //     this.form.value = 'true'
    //   } else {
    //     this.form.value = ''
    //   }
    // },
    /**
     * @desc ：增加可选值
     */
    onAddValue() {
      if(!this.isSuper) return
      this.typeList.push({ value: '', label: '' })
    },
    /**
     * @desc: 删除可选值
     */
    onDeleteValue(index){
      if(!this.isSuper) return
      this.typeList.splice(index,1)
      // this.typeList.push('')
    },
    /**
     * @desc:保存
     */
    onSave(){
      this.$refs['form'].validate((valid) => {
        if (!valid) {
          return false;
        }
        this.form.type = this.type
        this.form.options = this.typeList
        editConfig(this.form,this.form.id).then(res=>{
          if(res.data && res.data.code !== 200){
            return false;
          }
          this.$emit('drawerSave');
        }).catch(() => {
          this.$message.error(`操作错误`);
        });
      })

    },
    /**
     * @desc:关闭
     */
    onClose() {
      this.drawers = false;
      this.$emit('drawerClose');
    },
  },
};
</script>
<style lang="less">
.config-edit-main{
  color: #444;
  text-align: left;
  .form-class{
    padding: 0px 24px 50px 24px;
    font-size: 14px;
    line-height: 1.5;
  }
  .el-form-item__label{
    padding: 0 0 8px !important;
    width:100% !important;
    text-align: left;
  }
  .bottom-btn{
    margin:0;
    position: absolute;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    right: 0;
    background: #fff;
    border-radius: 0 0 4px 4px;
    display: flex;
    justify-content: flex-end;
    padding-right: 43px;
  }
}

</style>
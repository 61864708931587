<template>
  <div class="config-dialog">
    <el-dialog
      :fullscreen="dialogFull"
      :visible.sync="dialogs"
      width="30%"
      :before-close="onClose"
    >
      <template slot="title">
        <div class="avue-crud__dialog__header">
          <span> 添加参数 </span>
          <div
            class="avue-crud__dialog__menu"
            @click="dialogFull ? (dialogFull = false) : (dialogFull = true)"
          >
            <i class="el-icon-full-screen"></i>
          </div>
        </div>
      </template>

      <el-form
        label-width="80px"
        :model="form"
        class="add-form"
        label-position="top"
        :rules="rules"
        ref="dialog"
      >
        <el-row :gutter="15">
          <el-col :span="12">
            <el-form-item label="参数名称" prop="name">
              <el-input class="form-input" v-model="form.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="参数编码" prop="code">
              <el-input class="form-input" v-model="form.code"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="15">
          <el-col :span="12">
            <el-form-item label="控件类型" prop="type">
              <el-select
                v-model="type"
                placeholder="请选择控件类型"
                @change="typeChange"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="可选项">
              <div v-if="type === 'select' || type === 'radio'">
                <span
                  v-for="(item, index) in typeList"
                  :key="index"
                  style="
                    display: flex;
                    margin-bottom: 5px;
                    justify-content: space-between;
                  "
                >
                  <el-input
                    style="width: 40%"
                    v-model="typeList[index].label"
                    placeholder="请输入label"
                  ></el-input>
                  <el-input
                    style="width: 40%"
                    v-model="typeList[index].value"
                    placeholder="请输入value"
                  ></el-input>
                  <i
                    class="iconfont iconjian"
                    style="margin-left: 5px"
                    @click="onDeleteValue(index)"
                  ></i>
                </span>
                <i
                  class="iconfont iconjia"
                  style="margin-left: 5px"
                  @click="onAddValue"
                ></i>
              </div>
              <div v-if="type === 'switch'">
                <el-switch
                  v-model="switchValue"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                >
                </el-switch>
              </div>
              <Upload
                @onSuccess="onSuccess"
                :title="'上传图片'"
                v-if="type === 'upload'"
              >
              </Upload>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="15">
          <el-col :span="12">
            <el-form-item label="参数值" prop="value">
              <div v-if="type === 'select' || type === 'radio'">
                <el-select
                  v-model="form.value"
                  placeholder="请选择参数值"
                >
                  <el-option
                    v-for="(item, index) in typeList"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <el-input
                v-else
                class="form-input"
                :disabled="type === 'switch' || type === 'upload'"
                v-model="form.value"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="是否内置参数" prop="sort">
              <el-radio-group v-model="form.is_inside">
                <el-radio-button label="1">是</el-radio-button>
                <el-radio-button label="0">否</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="备注">
          <el-input
            class="form-input"
            type="textarea"
            v-model="form.remarks"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button size="mini" @click="onClose">取消</el-button>
        <el-button size="mini" type="primary" @click="onSave">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Upload from '../../../personalCenter/components/companyConfig/components/upload'
import { addConfig } from '@/api/saasManage'
import { RadioButton, RadioGroup,  } from 'element-ui'
export default {
  name: 'AddDialog',
  components: {
    Upload,
    'el-radio-group': RadioGroup,
    'el-radio-button': RadioButton,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      typeList: [{ value: '', label: '' }],
      type: '', // 控件类型
      options: [
        {
          value: 'input',
          label: '输入框',
        },
        {
          value: 'radio',
          label: '单选框',
        },
        {
          value: 'select',
          label: '下拉框',
        },
        {
          value: 'switch',
          label: '开关',
        },
        {
          value: 'upload',
          label: '文件上传',
        },
      ],
      dialogs: false,
      dialogFull: false,
      form: {
        is_inside: 1, // 是否内置
        name: '', //参数名称
        remarks: '', // 备注
        code: '', //编码
        value: '', //参数
      },
      switchValue: true,
      rules: {
        code: [
          { required: true, message: '参数编码不能为空', trigger: 'blur' },
        ],
        name: [{ required: true, message: '名称不能为空', trigger: 'blur' }],
        value: [{ required: true, message: '数据值不能为空', trigger: 'blur' }],
      },
    }
  },
  watch: {
    dialog(newVal) {
      this.dialogs = newVal
    },
    switchValue(newVal) {
      this.form.value = newVal + ''
    },
  },
  methods: {
    onSuccess(data){
      this.form.value = data
    },
    typeChange(data) {
      if (data === 'switch') {
        this.form.value = 'true'
      } else {
        this.form.value = ''
      }
    },
    /**
     * @desc ：增加可选值
     */
    onAddValue() {
      this.typeList.push({ value: '', label: '' })
    },
    /**
     * @desc: 删除可选值
     */
    onDeleteValue(index) {
      this.typeList.splice(index, 1)
      // this.typeList.push('')
    },
    /**
     * @desc: 保存
     */
    onSave() {
      this.$refs['dialog'].validate((valid) => {
        if (valid) {
          this.form.type = this.type
          this.form.options = this.typeList
          addConfig(this.form)
            .then((res) => {
              if (res.data && res.data.code !== 200) {
                return false
              }
              this.$emit('dialogSave')
              this.form = {
                is_inside: 1, // 是否内置
                name: '', //参数名称
                remarks: '', // 备注
                code: '', //编码
                value: '', //参数值
              }
            })
            .catch(() => {
              this.$message.error(`操作错误`)
            })
        } else {
          return false
        }
      })
    },
    /**
     * @desc:关闭
     */
    onClose() {
      this.dialogs = false
      this.form = {
        is_inside: 1, // 是否内置
        name: '', //参数名称
        remarks: '', // 备注
        code: '', //编码
        value: '', //参数值
      }
      this.$emit('dialogClose')
    },
  },
}
</script>
<style lang="less">
.config-dialog {
  .add-form {
    height: 310px;
    overflow: auto;
    padding-bottom: 10px;
  }
}
</style>
<style lang="less" scoped>
@import '../../../../css/manageAdd.less';
</style>

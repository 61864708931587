<!--
 * @Author: zyf
 * @Date: 2022-08-30 11:23:56
 * @LastEditTime: 2022-12-30 14:55:16
 * @Description: 
 * @LastEditors: 朱廷果 1028509503@qq.com
-->
<template>
  <div v-loading="userLoading">
    <el-upload ref="upload" 
      class="upload-demo" 
      :class="avatar ? '' : 'avatar-uploader'"
      :show-file-list="false" 
      :on-success="changeAvatar" 
      :action="baseURL + 'api/mapi?__method_name__=saasFile'" 
      :headers="{'bimcctoken': token,}"
      >
        <div
          :style="'height:'+width+';width:'+width"
          class="avatar-mask"
          v-if="showMask"
          @mouseout="showMask = 0"
        >
          {{title}}
        </div>
        <img
          v-if="avatar"
          class="show-img"
          @mouseover="showMask = 1"
          :src="avatar"/>
        <i v-else class="el-icon-plus avatar-uploader-icon" style="width:100%;height:100%"></i>
      </el-upload>
  </div>
</template>
<script>
  import { Upload } from 'element-ui';
  import { getToken } from "@/utils/tools";
  import { baseUrl } from '@/libs/util';
  export default {
      components: {
        'el-upload':Upload,
      },
      props:{
        width:{
          type:String,
          default:'100px'
        },
        dataId:{
          type:Number,
          default:null
        },
        imgUrl:{
          type:String,
          default:''
        },
        title:{
          type:String,
          default:'修改头像'
        },

      },
      data(){
        return {
          token:getToken(),
          showMask: 0,
          userLoading:false,
          avatar: '',
          baseURL: baseUrl(),
        }
      },

      watch:{
        imgUrl:{
          handler(url){
            this.avatar = url
          },
          deep:true,
          immediate:true
        }
      },
  
      mounted() {
        
      },
      methods:{
        changeAvatar(res){
          this.userLoading = true
          if(res.code == 200){
            this.avatar = res.data.url
            this.userLoading = false
            this.$emit('onSuccess',this.avatar)
          }
        },
      }
  }
  </script>
  <style scoped lang='less'>
    .upload-demo{
      text-align: left;
    }
    .avatar-mask {
      text-align: center;
      background-image: initial;
      background-color: rgba(10, 10, 10, 0.4);
      color: #fff;
      width: 100px;
      height: 100px;
      line-height: 100px;
      border-radius: 6px;
      overflow: hidden;
      position: absolute;
      cursor: pointer;
    }
    .show-img{
      width: 100%;
      height: 100%;
    }
    .avatar-uploader{
      width: 100px;
      height: 100px;
      border: 1px dashed #d9d9d9;
      overflow: hidden;
      border-radius: 6px;
      cursor: pointer;
      text-align: center;
      line-height: 100px;
    }
    .avatar-uploader:hover {
      border-color: #409EFF;
    }
    .upload-demo{
      width:100%;
      height: 100%;
      :deep(.el-upload) {
        width: 100%;
        height: 100%;
      }
    }
  </style>

<template>
  <div class="config-main">
    <div class="main-title">
      <div class="main-label">参数管理</div>
      <div>
        <el-button v-if="!isSuper && judgingAPermission(['config.import'])" class="float-right" @click="importSetting" 
          >导入</el-button
        >
        <el-button
          v-if="judgingAPermission(['config.store']) && isSuper"
          size="mini"
          type="primary"
          icon="el-icon-plus"
          @click="dialog = !dialog"
          >新增</el-button
        >

        <el-popconfirm
          v-if="judgingAPermission(['config.delete'])"
          class="delete-btn"
          title="确定要删除这个参数吗？"
          @confirm="onSelectDelete()"
        >
          <el-button
            v-if="
              parentSelectId &&
              parentSelectId.length &&
              judgingAPermission(['config.delete']) && isSuper
            "
            size="mini"
            type="danger"
            slot="reference"
            icon="el-icon-delete-solid"
            >删除</el-button
          >
        </el-popconfirm>
      </div>
    </div>
    <el-table
      v-loading="loading"
      :data="configData"
      :header-cell-style="{ background: '#fafafa' }"
      class="config-table"
      @selection-change="onSelectChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column label="参数编码" prop="code"></el-table-column>
      <el-table-column label="参数名称" prop="name"></el-table-column>
      <el-table-column label="参数值" prop="value"> 
        <template #default="{ row }">
          <div v-if="row.type !== 'upload'">{{ row.value}}</div>
          <img v-else :src="row.value">
        </template>
      </el-table-column>
      <el-table-column label="是否内置参数" align="center" width="150px">
        <template #default="{ row }">
          <div>{{ row.is_inside ? '是' : '否' }}</div>
        </template>
      </el-table-column>
      <el-table-column label="备注" prop="remarks"> </el-table-column>
      <el-table-column
        label="创建时间"
        prop="created_at"
        align="left"
        width="150px"
      ></el-table-column>
      <el-table-column label="操作" align="center">
        <template #default="{ row }" width="200px">
          <el-button
            v-if="judgingAPermission(['config.update'])"
            size="mini"
            type="text"
            @click="onEdit(row)"
            >修改</el-button
          >
          <el-popconfirm
            v-if="judgingAPermission(['config.delete'])"
            class="delete-btn"
            title="确定要删除这个参数吗？"
            @confirm="onDelete(row)"
          >
            <el-button size="mini" type="text" slot="reference">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 页脚 -->
    <el-pagination
      @size-change="onSize"
      @current-change="onPage"
      :current-page="+pager.page"
      :page-sizes="[15, 20, 50, 100, 200]"
      :page-size="+pager.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="+pager.total"
    >
    </el-pagination>
    <!-- 编辑抽屉 -->
    <EditDrawer
      :drawer="drawer"
      :editData="editData"
      @drawerClose="drawerClose"
      @drawerSave="drawerSave"
    >
    </EditDrawer>
    <!-- 新增弹窗 -->
    <AddDialog
      :dialog="dialog"
      @dialogClose="dialogClose"
      @dialogSave="dialogSave"
    >
    </AddDialog>
    <userDialog
      :importDialog="importDialog"
      :importData="importData"
      @dialogClose="userDialogClose"
      @dialogSave="userDialogSave"
      >

    </userDialog>
  </div>
</template>

<script>
import { judgingAPermission } from '@/utils/tools'
import { getConfig, detConfig, adminSetting, configSelect } from '@/api/saasManage'
import { Popconfirm } from 'element-ui'
import EditDrawer from './EditDrawer.vue'
import AddDialog from './AddDialog.vue'
import UserDialog from './UserDialog.vue'
export default {
  components: {
    'el-popconfirm': Popconfirm,
    AddDialog,
    EditDrawer,
    UserDialog,
  },
  data() {
    return {
      importDialog:false,
      importData:null,
      isSuper:false,
      judgingAPermission: judgingAPermission,
      parentSelectId: [],
      loading: false,
      configData: [],
      drawer: false,
      dialog: false,
      editData: {},
      pager: {
        page: 1,
        size: 15,
        total: 0,
      },
      search: '',
    }
  },
  mounted() {
    this.isSuper = JSON.parse(localStorage.getItem("userInfo")).saas_admin
    this.getData()
  },
  methods: {
    /**
     * @desc: 获取导入数据
     */
    importSetting(){
      let idArr = []
      this.configData.forEach(r=>{
        idArr.push(r.id)
      })
      configSelect().then(res=>{
        if (res.data && res.data.code !== 200) {
          return false
        }
        this.importDialog = true;
        this.importData = res.data.data
      })
    },
    /**
     * @desc:关闭新增弹窗
     */
    dialogClose() {
      this.dialog = !this.dialog
    },
    userDialogClose(){
      this.importDialog = false
    },
    userDialogSave(){
      this.importDialog = false
      this.getData()
    },
    /**
     * @desc:新增弹窗点击保存按钮
     */
    dialogSave() {
      this.dialog = !this.dialog
      this.getData()
    },
    /**
     * @desc:修改抽屉点击保存按钮
     */
    drawerSave() {
      this.drawer = !this.drawer
      this.getData()
    },
    /**
     * @desc:关闭编辑抽屉
     */
    drawerClose() {
      this.drawer = !this.drawer
    },
    /**
     * @desc:打开编辑弹窗
     */
    onEdit(row) {
      this.editData = row
      this.drawer = !this.drawer
    },

    /**
     * @desc:普通删除
     */
    onDelete(row) {
      detConfig({ id: [row.id] })
        .then((res) => {
          if (res.data && res.data.code !== 200) {
            return false
          }
          this.getData()
        })
        .catch(() => {
          this.$message.error(`操作错误`)
        })
    },
    /**
     * @desc: 批量删除
     */
    onSelectDelete() {
      detConfig({ id: this.parentSelectId })
        .then((res) => {
          if (res.data && res.data.code !== 200) {
            return false
          }
          this.getData()
        })
        .catch(() => {
          this.$message.error(`操作错误`)
        })
    },
    /**
     * @desc:筛选
     */
    onSearch(data) {
      this.search = data
      this.getData()
    },
    onRefresh() {
      this.search = ''
      this.getData()
    },
    /**
     * @desc: 分页数量
     * @param {Number} size
     */
    onSize(size) {
      this.pager.page = 1
      this.pager.size = size
      this.getData()
    },
    /**
     * @desc: 切换页码
     * @param {Number} page
     */
    onPage(page) {
      this.pager.page = page
      this.getData()
    },
    /**
     * @desc:父表格选择时
     */
    onSelectChange(val) {
      this.parentSelectId = []
      val.forEach((data) => {
        this.parentSelectId.push(data.id)
      })
    },
    // /**
    //  * @desc: 得到参数管理列表
    //  */
    getData() {
      this.loading = true
      console.log(this.search)
      let params = {
        page: this.pager.page,
        size: this.pager.size,
        name: this.search,
      }
      if(this.isSuper){
        getConfig(params).then((res) => {
          if (res.status === 200 && res.data.code === 200) {
            this.configData = res.data.data.data
            this.pager = {
              page: res.data.data.current_page,
              size: res.data.data.per_page,
              total: res.data.data.total,
            }
          }
          this.loading = false
        })
        .catch(() => {
          this.$message.error(`操作错误`)
        })
      }else{
        adminSetting(params).then((res) => {
          if (res.status === 200 && res.data.code === 200) {
            if(res.data.data){
              this.configData = res.data.data.data
            }
          }
          this.loading = false
        })
        .catch(() => {
          this.$message.error(`操作错误`)
        })
      }

    },
  },
}
</script>
<style lang="less">
@import '../../../../css/manageMain.less';
.config-main {
  background-color: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  border-radius: 8px;
  .config-table {
    width: 100%;
    flex: 1;
    padding: 0 15px;
    overflow: auto;
  }
}
</style>
